var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.layout,
    { tag: "component", staticClass: "container-fluid", attrs: { id: "app" } },
    [
      _c("notifications", {
        attrs: {
          group: "notices",
          ignoreDuplicates: true,
          max: 1,
          role: "status",
          closeOnClick: true
        }
      }),
      _c(
        "loader-overlay",
        [
          _c(
            "transition",
            { attrs: { name: _vm.transition } },
            [_c("router-view")],
            1
          ),
          _c("Agreements"),
          _c("PasswordReset")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }