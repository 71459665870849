var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-app" }, [
    _c("header", [_c("Navigation")], 1),
    _c("main", { staticStyle: { padding: "0 15px" } }, [
      _c("content", [_vm._t("default")], 2),
      _c("footer")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }