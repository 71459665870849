var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.messages
    ? _c(
        "router-link",
        {
          staticClass: "px-2",
          attrs: {
            to: _vm.linkto,
            title: _vm.messages.total.toString() + " unread"
          }
        },
        [
          _c(
            "span",
            { staticClass: "m-0" },
            [
              _c("span", { staticClass: "nav-link d-inline d-lg-none pr-1" }, [
                _vm._v("Messages")
              ]),
              _vm.linkto === "/messaging"
                ? _c("b-icon", {
                    class: _vm.messages.total > 0 ? "text-danger" : "",
                    attrs: {
                      icon:
                        _vm.messages.total > 0 ? "envelope-fill" : "envelope"
                    }
                  })
                : _vm._e(),
              _vm.linkto === "/feedback"
                ? _c("b-icon", {
                    class: _vm.messages.total > 0 ? "text-danger" : "",
                    attrs: {
                      icon:
                        _vm.messages.total > 0
                          ? "question-octagon-fill"
                          : "question-octagon"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm.messages.total
            ? _c(
                "b-badge",
                { staticClass: "py-1", attrs: { variant: "danger", pill: "" } },
                [_vm._v(_vm._s(_vm.messages.total))]
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }