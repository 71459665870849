var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: "pwResetModal",
            "ok-disabled": _vm.loading || _vm.$v.$invalid,
            "no-close-on-backdrop": "",
            "ok-variant": "success",
            "ok-title": "Submit",
            "no-close-on-esc": "",
            "no-stacking": "",
            "hide-header": "",
            "ok-only": ""
          },
          on: {
            ok: _vm.submit,
            hidden: function($event) {
              return _vm.reset()
            },
            shown: function($event) {
              return _vm.reset()
            }
          }
        },
        [
          _c("h3", [_vm._v("Your password needs to be reset")]),
          _c("br"),
          _c(
            "b-overlay",
            { attrs: { show: _vm.loading } },
            [
              _c(
                "b-form",
                {
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.submit(null)
                    }
                  }
                },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: "New Password" } },
                    [
                      _c("b-form-input", {
                        attrs: {
                          state: !_vm.$v.user.password.$error ? null : false,
                          placeholder: "New Password",
                          type: "password",
                          require: ""
                        },
                        on: {
                          blur: function($event) {
                            return _vm.$v.user.password.$touch()
                          }
                        },
                        model: {
                          value: _vm.$v.user.password.$model,
                          callback: function($$v) {
                            _vm.$set(_vm.$v.user.password, "$model", $$v)
                          },
                          expression: "$v.user.password.$model"
                        }
                      }),
                      !_vm.$v.user.password.required
                        ? _c("b-form-invalid-feedback", [
                            _vm._v(" Password is required ")
                          ])
                        : _vm._e(),
                      !_vm.$v.user.password.minLength
                        ? _c("b-form-invalid-feedback", [
                            _vm._v(
                              " Password must be at least 6 characters long "
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    { attrs: { label: "Repeat Password" } },
                    [
                      _c("b-form-input", {
                        attrs: {
                          state: !_vm.$v.user.password_confirm.$error
                            ? null
                            : false,
                          placeholder: "Repeat Password",
                          type: "password",
                          require: ""
                        },
                        on: {
                          blur: function($event) {
                            return _vm.$v.user.password_confirm.$touch()
                          }
                        },
                        model: {
                          value: _vm.$v.user.password_confirm.$model,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.$v.user.password_confirm,
                              "$model",
                              $$v
                            )
                          },
                          expression: "$v.user.password_confirm.$model"
                        }
                      }),
                      !_vm.$v.user.password_confirm.required
                        ? _c("b-form-invalid-feedback", [
                            _vm._v(" Password is required ")
                          ])
                        : _vm._e(),
                      !_vm.$v.user.password_confirm.sameAsPassword
                        ? _c("b-form-invalid-feedback", [
                            _vm._v(" Passwords must match ")
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }