var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-app" }, [
    _c("header", [_c("Navigation", { attrs: { type: "client" } })], 1),
    _c(
      "main",
      { staticStyle: { padding: "0 15px" } },
      [
        _vm.info && _vm.info.alertUnreadMessages && _vm.info.unreadMessages > 0
          ? _c(
              "b-alert",
              {
                attrs: {
                  show: "",
                  variant: "danger",
                  dismissible: "",
                  fade: ""
                },
                on: { dismissed: _vm.hideUnreadMessagesAlert }
              },
              [
                _vm._v(
                  " You have " +
                    _vm._s(_vm.info.unreadMessages) +
                    " unread messages! "
                ),
                _c("router-link", { attrs: { to: "/feedback" } }, [
                  _vm._v("Review them now!")
                ])
              ],
              1
            )
          : _vm._e(),
        _c("content", [_vm._t("default")], 2),
        _c("footer")
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }