import { render, staticRenderFns } from "./PasswordReset.vue?vue&type=template&id=169c0574&"
import script from "./PasswordReset.vue?vue&type=script&lang=js&"
export * from "./PasswordReset.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/websites/streamlined-business-solutions/sbs-client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('169c0574')) {
      api.createRecord('169c0574', component.options)
    } else {
      api.reload('169c0574', component.options)
    }
    module.hot.accept("./PasswordReset.vue?vue&type=template&id=169c0574&", function () {
      api.rerender('169c0574', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/PasswordReset.vue"
export default component.exports