var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: "termsAgreementModal",
            "ok-disabled": !_vm.termsAgreed || _vm.termsLoading,
            "no-close-on-backdrop": "",
            "ok-variant": "success",
            "ok-title": "I Agree",
            "no-close-on-esc": "",
            "no-stacking": "",
            "hide-header": "",
            "ok-only": ""
          },
          on: {
            ok: function($event) {
              return _vm.agree("terms", $event)
            },
            hidden: function($event) {
              return _vm.reset("terms")
            },
            shown: function($event) {
              return _vm.reset("terms")
            }
          }
        },
        [
          _c(
            "b-overlay",
            { attrs: { show: _vm.termsLoading } },
            [
              _c(
                "b-row",
                { attrs: { "align-v": "center" } },
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          model: {
                            value: _vm.termsAgreed,
                            callback: function($$v) {
                              _vm.termsAgreed = $$v
                            },
                            expression: "termsAgreed"
                          }
                        },
                        [
                          _vm._v(
                            " By checking this box you agree that you have read and understand the "
                          ),
                          _c(
                            "a",
                            {
                              staticStyle: { color: "#007bff" },
                              attrs: {
                                href:
                                  "https://streamlinedbusinesssolutions.com/terms-of-service/"
                              }
                            },
                            [_vm._v("terms of service")]
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "userAgreementModal",
            "ok-disabled": !_vm.userAgreed || _vm.userLoading,
            "no-close-on-backdrop": "",
            "ok-variant": "success",
            "ok-title": "I Agree",
            "no-close-on-esc": "",
            "no-stacking": "",
            "hide-header": "",
            "ok-only": ""
          },
          on: {
            ok: function($event) {
              return _vm.agree("user", $event)
            },
            hidden: function($event) {
              return _vm.reset("user")
            },
            shown: function($event) {
              return _vm.reset("user")
            }
          }
        },
        [
          _c(
            "b-overlay",
            { attrs: { show: _vm.userLoading } },
            [
              _c(
                "b-row",
                { attrs: { "align-v": "center" } },
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          model: {
                            value: _vm.userAgreed,
                            callback: function($$v) {
                              _vm.userAgreed = $$v
                            },
                            expression: "userAgreed"
                          }
                        },
                        [
                          _vm._v(
                            " By checking this box you agree and understand that the dashboard is for informational purposes only to help understand financial information, and in no way substitutes the accurate financial reports presented by SBS at year end for Tax Preparation. Third party access may be used for the purpose of obtaining necessary data to meet agreed upon terms. "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "dashAgreementModal",
            "ok-disabled": _vm.dashLoading,
            size: "lg",
            width: "95%",
            "max-width": "500px",
            "no-close-on-backdrop": "",
            "ok-variant": "success",
            "ok-title": "I Agree",
            "hide-header-close": "",
            "no-close-on-esc": "",
            "no-stacking": "",
            "ok-only": "",
            "header-class": "justify-content-center",
            "body-class": "justify-content-center",
            "footer-class": "justify-content-center"
          },
          on: {
            ok: function($event) {
              return _vm.agree("dash", $event)
            },
            hidden: function($event) {
              return _vm.reset("dash")
            },
            shown: function($event) {
              return _vm.reset("dash")
            }
          }
        },
        [
          _c(
            "div",
            {
              staticStyle: { margin: "0 auto" },
              attrs: { slot: "modal-title" },
              slot: "modal-title"
            },
            [
              _c("center", [
                _c("img", {
                  staticStyle: { height: "35px", width: "74%" },
                  attrs: { src: "/img/logo/black_red.png" }
                })
              ]),
              _c(
                "h5",
                {
                  staticStyle: {
                    "justify-content": "center",
                    "text-align": "center"
                  }
                },
                [_vm._v("Dashboard Terms and Conditions")]
              )
            ],
            1
          ),
          _c(
            "b-overlay",
            { attrs: { show: _vm.dashLoading } },
            [
              _c(
                "b-row",
                { attrs: { "align-v": "center" } },
                [
                  _c("b-col", { attrs: { cols: "12" } }, [
                    _c("p", [
                      _vm._v(
                        " Our company is pleased to provide clients with the opportunity to utilize their DIRECT financial data for benchmarking analysis. By leveraging this valuable information, businesses can gain valuable insights into industry performance, financial benchmarks, and competitive trends. By accepting these terms, the client authorizes the company to use their DIRECT financial data in an anonymous and confidential manner, in accordance with applicable privacy laws and regulations. This anonymized approach ensures privacy while enabling accurate and objective analysis. Leveraging our clients' DIRECT financial data for benchmarking analysis paves the way for informed decision-making, strategic growth, and improved performance within the confines of legal compliance. "
                      )
                    ])
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }